import { create } from "zustand";
import { persist } from "zustand/middleware";
import { immer } from 'zustand/middleware/immer'

const initialState = {
  project: null,
  projects: [],
};

const storeConfig = (set, get) => ({
  ...initialState,

  setProject: (project, append = false) => {
    set((state) => {
      state.project = project;
      if (append) {
        state.projects.push(project);
      }
    });
  },

  setProjects: (projects) => {
    set(() => ({ projects }))
  },

  setProjectStatus: (status) => {
    set((draft) => {
      draft.project.status = status;
    })
  }
});

export const useStore = create(
  persist(immer(storeConfig), {
    name: "project-storage",
    partialize: ({ project }) => {
      return { project };
    }
  })
);
