import './App.css';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import Home from './pages/Home/HomePage';
import { Layout } from './layouts/Layout';
import { RecordsTable } from './pages/Records/RecordsTable';
import { GeneratePage } from './pages/Generate/GeneratePage';
import { ReceiptsTable } from './pages/Settings/Receipts/ReceiptsTable';
import { GasTable } from './pages/Settings/Gas/GasTable';
import { useErrorStore } from './services/stores/errors';
import { ErrorModal } from './components/modals/ErrorModal';
import { useEffect, useState } from 'react';
import { AxiosInterceptor } from './services/axiosSettings';
import { Threshold } from './pages/Settings/Threshold/Threshold';

function App() {
    const [error, setError] = useErrorStore((state) => [state.error, state.setError]);
    const [isErrorModalDisplayed, setIsErrorModalDisplayed] = useState(false);

    useEffect(() => {
        console.log('error:', error);
        setIsErrorModalDisplayed(error ? true : false);
    }, [error]);

    return (
        <div className="App">
            <BrowserRouter>
                <AxiosInterceptor>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route path="/" element={<Home />} />
                            <Route path="/records" element={<RecordsTable />} />
                            <Route path="/generate" element={<GeneratePage />} />
                            <Route path="/receipts" element={<ReceiptsTable />} />
                            <Route path="/gas" element={<GasTable />} />
                            <Route path="/threshold" element={<Threshold />} />
                        </Route>
                    </Routes>
                </AxiosInterceptor>
            </BrowserRouter>
            <ErrorModal
                showState={isErrorModalDisplayed}
                onClose={() => {
                    setError(null);
                    setIsErrorModalDisplayed(false);
                }}
            />
        </div>
    );
}

export default App;
