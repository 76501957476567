import React, { useState, useRef } from 'react';
import { Button, Modal, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ProjectForm } from '../../pages/Projects/ProjectForm';
import { CarsForm } from '../../pages/Settings/Cars/CarsForm';
import { postCar } from '../../services/CarsService';
import { postProject } from '../../services/ProjectsService';
import { getProjectStatus, postRecordsFileById } from '../../services/RecordsService';
import { useStore } from '../../services/stores/projects';
import { FilesDropzone } from '../Dropzone';
import { AiOutlineClose, AiFillCloseSquare } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';

export const CreateProjectModal = ({ showState, onClose }) => {
    const navigate = useNavigate();
    const submitRef = useRef();
    const submitCarRef = useRef();
    const [step, setStep] = useState(1);
    const [file, setFile] = useState(null);
    const [car, setCar] = useState({});
    const [projectName, setProjectName] = useState('');
    const [project, setProject, setProjectStatus] = useStore((state) => [
        state.project,
        state.setProject,
        state.setProjectStatus,
    ]);

    const handleDrop = (files) => {
        setFile(files[0]);
    };

    const handleSubmit = () => {
        postProject(projectName).then((project) => {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                postRecordsFileById(project.id, formData).then(() => {
                    getProjectStatus(project.id).then((resp) => {
                        setProjectStatus(resp?.status);
                    });
                });
            }
            console.log({ car: car, project: project.id });
            postCar(car, project.id).then((car) => {
                project.car = car;
                setProject(project, true);
            });
        });
        setStep(0);
        resetComp();
        onClose();
        navigate('/');
    };

    const resetComp = () => {
        setProjectName(null);
        setStep(1);
        setCar(null);
        setFile(null);
    };

    const getSteps = () => {
        switch (step) {
            case 1:
                return (
                    <ProjectForm
                        parentCallback={(projectName) => {
                            setStep(2);
                            setProjectName(projectName);
                        }}
                        submitRef={submitRef}
                    />
                );
            case 2:
                return (
                    <>
                        <CarsForm
                            submitRef={submitCarRef}
                            parentCallback={(car) => {
                                setCar(car);
                                setStep(3);
                            }}
                        />
                    </>
                );
            case 3:
                return (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <FilesDropzone onDrop={handleDrop} accept="text/csv" />
                        {file && (
                            <div className="mt-2 d-inline-flex justify-content-center align-items-center p-2 border">
                                <span>
                                    File: {file.name}
                                    <Button
                                        onClick={() => {
                                            setFile(null);
                                        }}
                                        className="ms-2 mt-0 pt-0"
                                        variant=""
                                    >
                                        <GrFormClose />
                                    </Button>
                                </span>
                            </div>
                        )}
                    </div>
                );
            case 4:
                return (
                    <div className="d-flex flex-column align-items-center justify-content-center">
                        <strong>
                            <p>Project Name: {projectName}</p>
                            <p>Car: {car.make + ' ' + car.model}</p>
                            {file && <p>File: {file.name}</p>}
                        </strong>
                    </div>
                );
        }
    };

    const getButton = () => {
        switch (step) {
            case 1:
                return (
                    <Button
                        variant="primary"
                        onClick={() => {
                            submitRef.current.click();
                        }}
                    >
                        Next
                    </Button>
                );
            case 2:
                return (
                    <Button
                        variant="primary"
                        onClick={() => {
                            submitCarRef.current.click();
                        }}
                        disabled={file ? true : false}
                    >
                        Next
                    </Button>
                );
            case 3:
                return (
                    <>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setFile(null);
                                setStep(4);
                            }}
                        >
                            Skip
                        </Button>
                        <Button
                            variant="primary"
                            disabled={file ? false : true}
                            onClick={() => {
                                setStep(4);
                            }}
                        >
                            Next
                        </Button>
                    </>
                );
            case 4:
                return (
                    <Button
                        type="submit"
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        Create
                    </Button>
                );
        }
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create a new project</Modal.Title>
            </Modal.Header>
            <Modal.Body>{getSteps()}</Modal.Body>
            <Modal.Footer>
                <Button
                    className="me-auto"
                    disabled={step > 1 ? false : true}
                    variant="primary"
                    onClick={() => setStep(step - 1)}
                >
                    Back
                </Button>

                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                {getButton()}
            </Modal.Footer>
        </Modal>
    );
};
