import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Spinner, Table } from 'react-bootstrap';
import { BsFillPlusCircleFill, BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { CreateReceiptModal } from '../../../components/modals/CreateReceiptModal';
import { DeleteReceiptModal } from '../../../components/modals/DeleteReceiptModal';
import { DropzoneModal } from '../../../components/modals/DropzoneModal';
import { EditReceiptModal } from '../../../components/modals/EditReceiptModal';
import { getReceipts, postReceiptsCSV } from '../../../services/ReceiptsService';
import { useStore } from '../../../services/stores/projects';

export const ReceiptsTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [receipts, setReceipts] = useState([]);
    const [isDropzoneModalDisplayed, setIsDropzoneModalDisplayed] = useState(false);
    const [isCreateReceiptModalDisplayed, setIsCreateReceiptModalDisplayed] = useState(false);
    const [isEditReceiptModalDisplayed, setIsEditReceiptModalDisplayed] = useState(false);
    const [isDeleteReceiptModalDisplayed, setIsDeleteReceiptModalDisplayed] = useState(false);
    const [actionedReceipt, setActionedReceipt] = useState(null);
    const projectId = useStore((state) => state.project?.id);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getReceipts(projectId).then((response) => {
            if (response) {
                setReceipts(response);
            };
            setIsLoading(false);
        });
    }, [projectId]);

    const uploadFile = (file) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('project', projectId);
            postReceiptsCSV(formData).then(() => {
                refreshReceipts();
            });
        }
    };

    const refreshReceipts = () => {
        setIsLoading(true);
        getReceipts(projectId).then((response) => {
            setReceipts(response);
            setIsLoading(false);
        });
        setActionedReceipt(null);
    };

    return (
        <Card className="m-4">
            <Container fluid className="p-5">
                {isLoading ? (
                    <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <div>
                        <div className="row align-items-center mb-5">
                            <div className="col-4 offset-4">
                                <h3 className="mb-0">Receipts</h3>
                            </div>
                            <div className="col-4 d-flex pe-3 justify-content-end">
                                <Button className="me-2" onClick={() => setIsCreateReceiptModalDisplayed(true)}>
                                    <BsFillPlusCircleFill />
                                </Button>
                                <Button onClick={() => setIsDropzoneModalDisplayed(true)}>Import CSV</Button>
                                <CreateReceiptModal
                                    showState={isCreateReceiptModalDisplayed}
                                    onClose={() => setIsCreateReceiptModalDisplayed(false)}
                                    refreshReceipts={() => {
                                        setIsLoading(true);
                                        getReceipts(projectId).then((response) => {
                                            setReceipts(response);
                                            setIsLoading(false);
                                        });
                                    }}
                                />
                                <DropzoneModal
                                    showState={isDropzoneModalDisplayed}
                                    onClose={(file) => {
                                        uploadFile(file);
                                        setIsDropzoneModalDisplayed(false);
                                    }}
                                    helpTextFor="receipts"
                                />
                                <a
                                    href={`${apiUrl}/api/projects/${projectId}/export/receipts/`}
                                    download
                                    className="btn btn-primary ms-2"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                        {receipts.length ? (
                            <Table bordered responsive="lg">
                                <thead>
                                    <tr>
                                        <th>
                                            <strong>ID</strong>
                                        </th>
                                        <th>
                                            <strong>Name</strong>
                                        </th>
                                        <th>
                                            <strong>Price</strong>
                                        </th>
                                        <th>
                                            <strong>Address</strong>
                                        </th>
                                        <th>
                                            <strong>Date</strong>
                                        </th>
                                        <th>
                                            <strong>Type of Station</strong>
                                        </th>
                                        <th>
                                            <strong>Payment Method</strong>
                                        </th>
                                        <th>
                                            <strong>Actions</strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {receipts.map((receipt, index) => (
                                        <tr key={index}>
                                            <td>{receipt.id}</td>
                                            <td>{receipt.name}</td>
                                            <td>${receipt.price}</td>
                                            <td>{receipt.address}</td>
                                            <td>{DateTime.fromISO(receipt.date).toISODate()}</td>
                                            <td>{receipt.type_of_station}</td>
                                            <td>
                                                {receipt.payment_type.charAt(0).toUpperCase() +
                                                    receipt.payment_type.slice(1)}
                                            </td>
                                            <td width="10%">
                                                <Button
                                                    className="me-1 mb-1"
                                                    onClick={() => {
                                                        setIsEditReceiptModalDisplayed(true);
                                                        setActionedReceipt(receipt);
                                                    }}
                                                >
                                                    <BsPencilSquare />
                                                </Button>
                                                <Button
                                                    className="me-1 mb-1"
                                                    onClick={() => {
                                                        setIsDeleteReceiptModalDisplayed(true);
                                                        setActionedReceipt(receipt);
                                                    }}
                                                >
                                                    <BsFillTrashFill />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        ) : (
                            <h5>No Receipts</h5>
                        )}
                    </div>
                )}
                <EditReceiptModal
                    refreshReceipts={refreshReceipts}
                    actionedReceipt={actionedReceipt}
                    showState={isEditReceiptModalDisplayed}
                    onClose={() => {
                        setIsEditReceiptModalDisplayed(false);
                        setActionedReceipt(null);
                    }}
                />
                <DeleteReceiptModal
                    refreshReceipts={refreshReceipts}
                    actionedReceipt={actionedReceipt}
                    showState={isDeleteReceiptModalDisplayed}
                    onClose={() => {
                        setIsDeleteReceiptModalDisplayed(false);
                        setActionedReceipt(null);
                    }}
                />
            </Container>
        </Card>
    );
};
