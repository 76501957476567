import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteGas } from '../../services/GasService';

export const DeleteGasModal = ({ refreshGasList, actionedGas, showState, onClose }) => {
    const onDelete = () => {
        deleteGas(actionedGas.id).then(() => {
            refreshGasList();
        });
        onClose();
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete gas prices #{actionedGas?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This action is irevertible!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={onDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
