import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Table, Container, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router';
import { getGeneratedRecords } from '../../services/GenerateService';
import { useStore } from '../../services/stores/projects';
import { useSearchParams } from 'react-router-dom';
import { useErrorStore } from '../../services/stores/errors';
import { useCustomSearchParams } from '../../utils/hooks/queryParams';

const headings = [
    'ID',
    // "Project Name",
    'Type',
    'Reason',
    'Start Location',
    'End Location',
    'Datetime',
    'Distance',
    'Tags',
];

export const GeneratePage = () => {
    const [queryParams] = useCustomSearchParams();
    const [generatedRecords, setGeneratedRecords] = useState([]);
    const projectId = useStore((state) => state.project?.id);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [error, setError] = useErrorStore((state) => [state.error, state.setError]);

    useEffect(() => {
        if (!queryParams.hasOwnProperty('receipt') || !queryParams.hasOwnProperty('start_date')) {
            setError({ message: 'No receipt or start date received!' });
            navigate('/');
        }
        setLoading(true);
        getGeneratedRecords(projectId, { ...queryParams }).then((response) => {
            if (Array.isArray(response)) {
                setGeneratedRecords(response);
            }
            setLoading(false);
        });
    }, [projectId]);

    return (
        <Container fluid className="p-5">
            {loading ? (
                <div>
                    <Spinner animation="border" role="status" className="d-flex mx-auto mt-5"></Spinner>
                    <span>Generating...</span>
                </div>
            ) : (
                <div>
                    <div className="row align-items-center mb-5">
                        <div className="col-4 offset-4">
                            <h3 className="mb-0">Generated Records</h3>
                        </div>
                    </div>
                    <div>
                        <Table bordered responsive="lg">
                            <thead>
                                <tr>
                                    {headings.map((heading, index) => (
                                        <th key={index}>
                                            <strong>{heading}</strong>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {generatedRecords?.map((record, index) => (
                                    <>
                                        <tr key={index}>
                                            <td>{record.id}</td>
                                            <td>{record.type}</td>
                                            <td width="25%">{record.reason}</td>
                                            <td>{record.start_location?.original_address}</td>
                                            <td>{record.end_location.original_address}</td>
                                            <td width="7%">{DateTime.fromISO(record.datetime).toISODate()}</td>
                                            <td width="5%">{record.distance}</td>
                                            <td>
                                                {record.tags?.map((tag) => (
                                                    <>{tag}</>
                                                ))}
                                            </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            )}
        </Container>
    );
};
