import { DateTime } from 'luxon';
import axios from './axiosSettings';

export const getRecordsByProjectId = async (projectId, query) => {
    const { start_date, end_date } = query;

    try {
        const { start_date, end_date } = query;
        const response = await axios.get('/api/project/' + projectId + '/records/', {
            params: {
                ...query,
                start_date: start_date ? DateTime.fromJSDate(start_date).toISODate() : undefined,
                end_date: end_date ? DateTime.fromJSDate(end_date).toISODate() : undefined,
            },
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
export const getProjectStatus = async (projectId) => {
    try {
        const response = await axios.get('/api/projects/' + projectId + '/status/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postRecordsFileById = async (projectId, formData) => {
    try {
        const response = await axios.post(`/api/project/${projectId}/upload/`, formData, {
            headers: {
                'Content-type': 'multipart/form-date',
            },
        });
        return response;
    } catch (error) {
        console.log(error);
    }
};

export const getFilteredRecords = async (projectId, filters) => {
    if (filters.start_date === '') delete filters.start_date;

    if (filters.end_date === '') delete filters.end_date;

    try {
        const response = await axios.get('/api/project/' + projectId + '/records/', { params: filters });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getRecord = async (recordId) => {
    try {
        const response = await axios.get('api/record/' + recordId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postRecord = async (record) => {
    try {
        const response = await axios.post('/api/record/', record);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const patchRecord = async (recordId, record) => {
    try {
        const response = await axios.patch('/api/record/' + recordId + '/', record);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteRecord = async (recordId) => {
    try {
        const response = await axios.delete('/api/record/' + recordId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
