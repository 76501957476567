import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Badge, Button, Card, Container, Popover, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { CustomPagination } from '../../components/CustomPagination';
import { CreateRecordModal } from '../../components/modals/CreateRecordModal';
import { DeleteRecordModal } from '../../components/modals/DeleteRecordModal';
import { DropzoneModal } from '../../components/modals/DropzoneModal';
import { EditRecordModal } from '../../components/modals/EditRecordModal';
import { FilterModal } from '../../components/modals/FilterModal';
import { Truncate } from '../../components/Truncate';
import { getProjectStatus, getRecordsByProjectId, postRecordsFileById } from '../../services/RecordsService';
import { usePaginationStore } from '../../services/stores/pagination';
import { useStore } from '../../services/stores/projects';
import { FaFlag } from 'react-icons/fa';
import { IoReceiptOutline } from 'react-icons/io5';
import { useCustomSearchParams } from '../../utils/hooks/queryParams';
import { cleanObject } from '../../utils/cleanObject';
import { TbFlag, TbFlagOff } from 'react-icons/tb';

const headings = [
    'ID',
    'Generated',
    'Type',
    'Receipt',
    'Start Location',
    'End Location',
    'Datetime',
    'Distance',
    'Tags',
    'Actions',
];

export const RecordsTable = (props) => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [query, setQuery] = useCustomSearchParams();
    const [isFilterModalDisplayed, setisFilterModuleDisplayed] = useState(false);
    const [isDropModalDisplayed, setIsDropModalDispayed] = useState(false);
    const [count, setCount] = useState(0);
    const [IsAddRecordModalDisplayed, setIsAddRecordModalDisplayed] = useState(false);
    const [isEditRecordModalDisplayed, setIsEditRecordModalDisplayed] = useState(false);
    const [isDeleteRecordModalDisplayed, setIsDeleteRecordModalDisplayed] = useState(false);
    const [records, setRecords] = useState({});
    const [project, projectId, setProjectStatus] = useStore((state) => [
        state.project,
        state.project?.id,
        state.setProjectStatus,
    ]);
    const [loading, setLoading] = useState(false);
    const [actionedRecord, setActionedRecord] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = usePaginationStore((state) => [
        state.recordsPerPage,
        state.setRecordsPerPage,
    ]);
    const [recordsStats, setRecordsStats] = useState(null);

    useEffect(() => {
        setLoading(true);
        getRecordsByProjectId(projectId, {
            limit: recordsPerPage,
            offset: (currentPage - 1) * recordsPerPage,
            ...query,
        }).then((response) => {
            console.log(response.stats);
            setRecords(response.results);
            setRecordsStats(response.stats);
            setCount(response.count);
            setLoading(false);
        });
    }, [projectId, currentPage, recordsPerPage, setQuery, project?.status]);

    const uploadFile = (file, permanent = false) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('project', projectId);
            formData.append('permanent', permanent);
            postRecordsFileById(projectId, formData).then(() => {
                getProjectStatus(projectId).then((resp) => {
                    setProjectStatus(resp?.status);
                    refreshRecords();
                });
            });
        }
    };

    const refreshRecords = () => {
        setLoading(true);
        getRecordsByProjectId(projectId, {
            limit: recordsPerPage,
            offset: (currentPage - 1) * recordsPerPage,
            ...query,
        }).then((response) => {
            setRecords(response.results);
            setCount(response.count);
            setLoading(false);
        });
        setActionedRecord(null);
    };

    const reasonPopover = (reason) => (
        <Popover>
            <Popover.Header as="h3">Reason</Popover.Header>
            <Popover.Body>{reason}</Popover.Body>
        </Popover>
    );

    const locationPopover = (prefix, location) => (
        <Popover>
            <Popover.Header as="h3">{prefix} Location</Popover.Header>
            <Popover.Body>{location}</Popover.Body>
        </Popover>
    );

    const receiptPopover = (receipt) => {
        return (
            <Popover>
                <Popover.Header as="h3">Receipt</Popover.Header>
                <Popover.Body>{receipt ? <div>{receipt.name}</div> : <div>No receipt</div>}</Popover.Body>
            </Popover>
        );
    };

    const generatedTooltip = (generated) => {
        return <Tooltip>{generated ? 'Generated' : 'Not generated'}</Tooltip>;
    };

    return (
        <Card className="m-4">
            <Container fluid className="p-5">
                {loading ? (
                    <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <div>
                        <div className="row align-items-center mb-5">
                            <div className="col-2">
                                {recordsStats && (
                                    <Card body>
                                        <div>
                                            Personal {'(' + recordsStats.personal.percentage + '%)'}:
                                            <br />
                                            {recordsStats.personal.generated_mileage
                                                ? new Intl.NumberFormat('en-US').format(
                                                      recordsStats.personal.generated_mileage
                                                  )
                                                : 0}{' '}
                                            out of{' '}
                                            {new Intl.NumberFormat('en-US').format(recordsStats.personal.total_mileage)}
                                        </div>
                                        <div>
                                            Business {'(' + recordsStats.business.percentage + '%)'}:
                                            <br />
                                            {recordsStats.business.generated_mileage
                                                ? Intl.NumberFormat('en-US').format(
                                                      recordsStats.business.generated_mileage
                                                  )
                                                : 0}{' '}
                                            out of{' '}
                                            {new Intl.NumberFormat('en-US').format(recordsStats.business.total_mileage)}
                                        </div>
                                    </Card>
                                )}
                            </div>
                            <div className="col-2">
                                {project?.status && (
                                    <div className="d-flex me-auto align-items-center">
                                        <Spinner animation="border" role="status"></Spinner>
                                        <span className="ms-3">Still Processing Records...</span>
                                    </div>
                                )}
                            </div>
                            <div className="col-4">
                                <h3 className="mb-0">Records</h3>
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                                <Button
                                    onClick={() => {
                                        setisFilterModuleDisplayed(true);
                                    }}
                                    className="me-2"
                                >
                                    Filter
                                </Button>

                                <Button onClick={() => setIsAddRecordModalDisplayed(true)} className="me-2">
                                    +
                                </Button>
                                <Button onClick={() => setIsDropModalDispayed(true)}>Add with CSV file</Button>
                                <a
                                    href={`${apiUrl}/api/projects/${projectId}/export/records/`}
                                    download
                                    className="btn btn-primary ms-2"
                                >
                                    Download
                                </a>
                            </div>
                        </div>
                        <CreateRecordModal
                            refreshRecords={() => {
                                setLoading(true);
                                getRecordsByProjectId(projectId, {
                                    limit: recordsPerPage,
                                    offset: (currentPage - 1) * recordsPerPage,
                                    ...query,
                                }).then((response) => {
                                    setRecords(response.results);
                                    setCount(response.count);
                                    setLoading(false);
                                });
                            }}
                            showState={IsAddRecordModalDisplayed}
                            onClose={() => {
                                setIsAddRecordModalDisplayed(false);
                            }}
                        />
                        <DropzoneModal
                            showState={isDropModalDisplayed}
                            onClose={(file, permanent) => {
                                uploadFile(file, permanent);
                                setIsDropModalDispayed(false);
                            }}
                            helpTextFor="records"
                        />
                        <FilterModal
                            filters={query}
                            filterSubmit={(filters) => setQuery(cleanObject(filters))}
                            showState={isFilterModalDisplayed}
                            onClose={() => setisFilterModuleDisplayed(false)}
                        />
                        {records.length ? (
                            <div>
                                <CustomPagination
                                    dataPerPage={recordsPerPage}
                                    currentPage={currentPage}
                                    totalData={count}
                                    paginate={(index) => {
                                        setCurrentPage(index);
                                        window.scrollTo(0, 0);
                                    }}
                                    setDataPerPage={(count) => {
                                        setRecordsPerPage(count);
                                        setCurrentPage(1);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                                <Table bordered striped responsive="lg">
                                    <thead>
                                        <tr>
                                            {headings.map((heading, index) => (
                                                <th key={index}>
                                                    <strong>{heading}</strong>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {records?.map((record, index) => (
                                            <>
                                                <tr key={index}>
                                                    <td rowSpan={2}>
                                                        <div className="d-flex justify-content-center align-items-center">
                                                            #{record.id}
                                                        </div>
                                                    </td>
                                                    <td width="2%" rowSpan={2}>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="right"
                                                            overlay={generatedTooltip(record.generated)}
                                                        >
                                                            <span>{record.generated ? <TbFlag /> : <TbFlagOff />}</span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td rowSpan={2}>{record.type}</td>
                                                    <td width="2%" rowSpan={2}>
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="bottom"
                                                            overlay={receiptPopover(record.receipt)}
                                                        >
                                                            <span>
                                                                <IoReceiptOutline />
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    {record.start_location ? (
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            overlay={locationPopover(
                                                                'Start',
                                                                record.start_location.original_address
                                                            )}
                                                        >
                                                            <td>
                                                                <Truncate
                                                                    text={record.start_location.original_address}
                                                                    size={30}
                                                                />
                                                            </td>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <td></td>
                                                    )}
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        placement="top"
                                                        overlay={locationPopover(
                                                            'End',
                                                            record.end_location.original_address
                                                        )}
                                                    >
                                                        <td>
                                                            <Truncate
                                                                text={record.end_location.original_address}
                                                                size={30}
                                                            />
                                                        </td>
                                                    </OverlayTrigger>
                                                    <td rowSpan={2} width="7%">
                                                        {DateTime.fromISO(record.datetime).toISODate()}
                                                    </td>
                                                    <td rowSpan={2} width="5%">
                                                        {record.distance}
                                                    </td>
                                                    <td rowSpan={2}>
                                                        {record.tags.map((tag) => (
                                                            <Badge bg="info">{tag}</Badge>
                                                        ))}
                                                    </td>
                                                    <td rowSpan={2} width="10%">
                                                        <Button
                                                            className="me-1 mb-1"
                                                            onClick={() => {
                                                                setIsEditRecordModalDisplayed(true);
                                                                setActionedRecord(record);
                                                            }}
                                                        >
                                                            <BsPencilSquare />
                                                        </Button>
                                                        <Button
                                                            className="me-1 mb-1"
                                                            onClick={() => {
                                                                setIsDeleteRecordModalDisplayed(true);
                                                                setActionedRecord(record);
                                                            }}
                                                        >
                                                            <BsFillTrashFill />
                                                        </Button>
                                                    </td>
                                                </tr>

                                                <tr style={{ display: 'none' }}></tr>

                                                <tr>
                                                    <OverlayTrigger
                                                        trigger={['hover', 'focus']}
                                                        placement="bottom"
                                                        overlay={reasonPopover(
                                                            record.reason ? record.reason : 'No reason given'
                                                        )}
                                                    >
                                                        <td colSpan={2} width="25%" className="text-truncate">
                                                            <Truncate
                                                                text={record.reason ? record.reason : 'No reason given'}
                                                                size={50}
                                                            />
                                                        </td>
                                                    </OverlayTrigger>
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
                                </Table>
                                <CustomPagination
                                    dataPerPage={recordsPerPage}
                                    currentPage={currentPage}
                                    totalData={count}
                                    paginate={(index) => {
                                        setCurrentPage(index);
                                        window.scrollTo(0, 0);
                                    }}
                                    setDataPerPage={(count) => {
                                        setRecordsPerPage(count);
                                        setCurrentPage(1);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            </div>
                        ) : (
                            <h2>No records yet</h2>
                        )}
                    </div>
                )}
            </Container>
            <EditRecordModal
                refreshRecords={refreshRecords}
                showState={isEditRecordModalDisplayed}
                actionedRecord={actionedRecord}
                onClose={() => {
                    setIsEditRecordModalDisplayed(false);
                    setActionedRecord(null);
                }}
            />

            <DeleteRecordModal
                refreshRecords={refreshRecords}
                actionedRecord={actionedRecord}
                showState={isDeleteRecordModalDisplayed}
                onClose={() => {
                    setIsDeleteRecordModalDisplayed(false);
                }}
            />
        </Card>
    );
};
