import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Spinner, Table } from 'react-bootstrap';
import { BsFillPlusCircleFill, BsFillTrashFill, BsPencilSquare } from 'react-icons/bs';
import { DeleteGasModal } from '../../../components/modals/DeleteGasModal';
import { DropzoneModal } from '../../../components/modals/DropzoneModal';
import { EditGasModal } from '../../../components/modals/EditGasModal';
import { getGas, postGasCSVFile } from '../../../services/GasService';
import { useStore } from '../../../services/stores/projects';

export const GasTable = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isDropModalDisplayed, setIsDropModalDispayed] = useState(false);
    const [gas, setGas] = useState([]);
    const [isEditGasModalDisplayed, setIsEditGasModalDisplayed] = useState(false);
    const [isDeleteGasModalDisplayed, setIsDeleteGasModalDisplayed] = useState(false);
    const [actionedGas, setActionedGas] = useState(null);
    const projectId = useStore((state) => state.project?.id);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getGas().then((response) => {
            setGas(response);
            setIsLoading(false);
        });
    }, [projectId]);

    const uploadFile = (file) => {
        if (file) {
            const formData = new FormData();
            formData.append('file', file);
            postGasCSVFile(formData).then(() => {
                refreshGasList();
            });
        }
    };

    const refreshGasList = () => {
        setIsLoading(true);
        getGas().then((response) => {
            setGas(response);
            setIsLoading(false);
        });
        setActionedGas(null);
    };

    return (
        <Card className="m-4">
            <Container fluid className="p-5">
                {isLoading ? (
                    <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <div>
                        <div className="row align-items-center mb-5">
                            <div className="col-4 offset-4">
                                <h5 className="mb-0">Gas Prices</h5>
                            </div>
                            <div className="col-4 d-flex justify-content-end pe-3">
                                <Button onClick={() => setIsDropModalDispayed(true)}>
                                    <BsFillPlusCircleFill className="me-2" />
                                    Import CSV
                                </Button>
                                <DropzoneModal
                                    showState={isDropModalDisplayed}
                                    onClose={(file) => {
                                        uploadFile(file);
                                        setIsDropModalDispayed(false);
                                    }}
                                    helpTextFor="gasprice"
                                />
                                <a href={`${apiUrl}/api/gas/export/`} download className="btn btn-primary ms-2">
                                    Download
                                </a>
                            </div>
                        </div>
                        <div>
                            {gas.length ? (
                                <Table bordered responsive="lg">
                                    <thead>
                                        <tr>
                                            <th>
                                                <strong>Gas Price</strong>
                                            </th>
                                            <th>
                                                <strong>Start Date</strong>
                                            </th>
                                            <th>
                                                <strong>End Date</strong>
                                            </th>
                                            <th>
                                                <strong>Actions</strong>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gas.map((item, index) => (
                                            <tr key={index}>
                                                <td>${item.price}</td>
                                                <td>{DateTime.fromISO(item.start_date).toISODate()}</td>
                                                <td>{DateTime.fromISO(item.end_date).toISODate()}</td>
                                                <td>
                                                    <Button
                                                        className="me-1 mb-1"
                                                        onClick={() => {
                                                            setIsEditGasModalDisplayed(true);
                                                            setActionedGas(item);
                                                        }}
                                                    >
                                                        <BsPencilSquare />
                                                    </Button>
                                                    <Button
                                                        className="me-1 mb-1"
                                                        onClick={() => {
                                                            setIsDeleteGasModalDisplayed(true);
                                                            setActionedGas(item);
                                                        }}
                                                    >
                                                        <BsFillTrashFill />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (
                                <h2>No Gas Prices</h2>
                            )}
                        </div>
                    </div>
                )}
                <EditGasModal
                    refreshGasList={refreshGasList}
                    actionedGas={actionedGas}
                    showState={isEditGasModalDisplayed}
                    onClose={() => {
                        setIsEditGasModalDisplayed(false);
                    }}
                />
                <DeleteGasModal
                    refreshGasList={refreshGasList}
                    actionedGas={actionedGas}
                    showState={isDeleteGasModalDisplayed}
                    onClose={() => {
                        setIsDeleteGasModalDisplayed(false);
                        getGas().then(setGas);
                        setActionedGas(null);
                    }}
                />
            </Container>
        </Card>
    );
};
