import React, { useState } from 'react';
import { Button, Form, Modal, OverlayTrigger, Popover } from 'react-bootstrap';
import { FilesDropzone } from '../Dropzone';
import { GrFormClose } from 'react-icons/gr';

export const DropzoneModal = ({ showState, onClose, helpTextFor }) => {
    const [droppedFile, setDroppedFile] = useState(null);
    const [permanent, setPermanent] = useState(false);
    let title = null;
    let downloadLink = null;
    const apiUrl = process.env.REACT_APP_API_URL;

    if (helpTextFor === 'gasprice') {
        title = 'Gas Prices';
        downloadLink = apiUrl + '/static/files/gas_prices.csv';
    } else if (helpTextFor === 'records') {
        title = 'Records';
        downloadLink = apiUrl + '/static/files/records.csv';
    } else if (helpTextFor === 'receipts') {
        title = 'Receipts';
        downloadLink = apiUrl + '/static/files/receipts.csv';
    }

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{title} CSV Example</Popover.Header>
            <Popover.Body>
                The imported file for <strong>'{title}'</strong> need to have this{' '}
                <a download href={downloadLink}>
                    format
                </a>
            </Popover.Body>
        </Popover>
    );

    const handleDrop = (files) => {
        setDroppedFile(files[0]);
    };

    const handleSubmit = () => {
        onClose(droppedFile, permanent);
        setDroppedFile(null);
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Drop your CSV here</Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
                <FilesDropzone onDrop={handleDrop} accept={'text/csv'} />
                {droppedFile && (
                    <div className="mt-2 d-inline-flex justify-content-center align-items-center p-2 border">
                        <span>
                            File: {droppedFile.name}
                            <Button
                                onClick={() => {
                                    setDroppedFile(null);
                                }}
                                className="ms-2 mt-0 pt-0"
                                variant=""
                            >
                                <GrFormClose />
                            </Button>
                        </span>
                    </div>
                )}
                <Form.Check
                    name="permanent"
                    label="Permenant Records (check this if records are permanent)"
                    value={permanent}
                    onChange={(e) => setPermanent(e.target.checked)}
                    className="mt-2"
                    id="field-permanent"
                />
            </Modal.Body>
            <Modal.Footer>
                <OverlayTrigger trigger="click" placement="left" overlay={popover}>
                    <Button variant="secondary">?</Button>
                </OverlayTrigger>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button disabled={droppedFile ? false : true} variant="primary" onClick={handleSubmit}>
                    Upload your file
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
