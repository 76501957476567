import React, { useRef } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { createSearchParams } from 'react-router-dom';
import { GenerateRecordsForm } from '../../pages/Generate/GenerateRecordsForm';
import { useStore } from '../../services/stores/projects';

export const GenerateRecordsModal = ({ showState, onClose }) => {
    const submitRef = useRef();
    const navigate = useNavigate();
    const project = useStore((state) => state.project);
    const handleSubmit = (data) => {
        navigate({ pathname: '/generate', search: `?${createSearchParams(data)}` });
        onClose();
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Generate new Records</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GenerateRecordsForm onClose={onClose} submitRef={submitRef} handleFormSubmit={handleSubmit} />
            </Modal.Body>
            <Modal.Footer>
                {project?.status && (
                    <div className="d-flex me-auto align-items-center">
                        <Spinner animation="border" role="status"></Spinner>
                        <span className="ms-3">Still Processing Records...</span>
                    </div>
                )}
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => submitRef.current.click()}>
                    Generate
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
