import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../services/stores/projects';
import { deleteProject, getProjectById } from '../../services/ProjectsService';
import { Button } from 'react-bootstrap';

export const DeleteProjectModal = ({ showState, onClose }) => {
    const navigate = useNavigate();
    const [projectId, setProject] = useStore((state) => [state.project.id, state.setProject]);
    const [projectName, setProjectName] = useState('');

    useEffect(() => {
        getProjectById(projectId).then((response) => {
            setProjectName(response.name);
        });
    }, [projectId]);

    const handleDelete = () => {
            deleteProject(projectId).then((response) => {
            console.log(response);
            setProject(null);
            navigate('/');
            onClose();
        });
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Are you sure you want to delete {projectName}?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This action is irevertible!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={handleDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
