import axios from './axiosSettings';
import { DateTime } from 'luxon';

export const getGeneratedRecords = async (projectId, query) => {
    const { receipt, start_date, end_date, tags } = query;

    try {
        const response = await axios('/api/project/' + projectId + '/generate/', {
            params: { ...query },
        });
        return response.data;
    } catch (error) {
        return error;
    }
};
