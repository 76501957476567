import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RecordForm } from '../../pages/Records/RecordForm';
import { postRecord } from '../../services/RecordsService';
import { useFormStore } from '../../services/stores/forms';
import { useStore } from '../../services/stores/projects';

export const CreateRecordModal = ({ refreshRecords, showState, onClose }) => {
    const submitRef = useRef();
    const projectId = useStore((state) => state.project?.id);
    const [record, setRecords] = useFormStore((state) => [state.record, state.setRecord]);

    useEffect(() => {
        if (record && showState) {
            postRecord({ ...record, project: projectId }).then((response) => {
                console.log(response);
                refreshRecords();
                onClose();
            });
        }
    }, [record]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add a record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RecordForm submitRef={submitRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        submitRef.current.click();
                    }}
                >
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
