import axios from './axiosSettings';

export const getCars = async () => {
    try {
        const response = await axios.get('/api/cars/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCarById = async (carId) => {
    try {
        const response = await axios.get('/api/cars/' + carId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postCar = async (car, projectId) => {
    try {
        const response = await axios.post('/api/cars/', {
            ...car,
            project: projectId,
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteCar = async (carId) => {
    try {
        const response = await axios.delete('/api/cars/' + carId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const patchCar = async (carId, newCar) => {
    try {
        const response = await axios.patch('/api/cars/' + carId + '/', newCar);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const putCar = async (carId, newCar) => {
    try {
        const response = await axios.put('/api/cars/' + carId, newCar);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getCarsByProject = async (proejctId) => {
    try {
        const response = await axios.get('/api/projects/' + proejctId + '/cars/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
