import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { RecordForm } from '../../pages/Records/RecordForm';
import { patchRecord } from '../../services/RecordsService';
import { useFormStore } from '../../services/stores/forms';

export const EditRecordModal = ({ actionedRecord, refreshRecords, showState, onClose }) => {
    const submitRef = useRef();
    const [record, setRecord] = useFormStore((state) => [state.record, state.setRecord]);

    useEffect(() => {
        if (record && showState) {
            patchRecord(actionedRecord.id, record).then((response) => {
                refreshRecords();
                setRecord(null);
                onClose();
            });
        }
    }, [record]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Record #{actionedRecord?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <RecordForm actionedRecord={actionedRecord} ref={submitRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        submitRef.current.click();
                    }}
                >
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
