import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, FloatingLabel, Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useStore } from '../../../services/stores/projects';
import { patchProject } from '../../../services/ProjectsService';

const FormSchema = z
    .object({
        business: z.coerce.number({ required_error: 'Age is required' }).nonnegative(),
        personal: z.coerce.number({ required_error: 'Age is required' }).nonnegative(),
    })
    .superRefine((arg, ctx) => {
        if (arg.business + arg.personal !== 100) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: "The numbers don't add up to 100",
            });
        }
        return z.NEVER;
    });

export const Threshold = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(FormSchema),
    });
    console.log(errors);
    const [project, projectId, setProject] = useStore((state) => [state.project, state.project?.id, state.setProject]);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        setValue('business', null);
        setValue('personal', null);
        if (project) {
            setIsLoading(false);
            if (project.threshold) {
                setValue('business', project.threshold.business);
                setValue('personal', project.threshold.personal);
            }
        }
    }, [projectId]);

    const onSubmit = (data) => {
        console.log(data);
        patchProject(projectId, { threshold: data }).then(setProject);
    };
    const onBusinessThresholdChange = (event) => {
        if (event.target.value) {
            setValue('personal', 100 - event.target.value);
        }
    };

    return (
        <Card className="m-4">
            <Container fluid className="p-5">
                {isLoading ? (
                    <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                ) : (
                    <div>
                        <div className="row align-items-center mb-5">
                            <div className="col-4 offset-4">
                                <h5 className="mb-0">Threshold</h5>
                            </div>
                        </div>
                        <div>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Row className="g-2">
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <FloatingLabel controlId="floatingInputGrid" label="Business">
                                                <Form.Control
                                                    type="number"
                                                    {...register('business')}
                                                    placeholder="qew"
                                                    aria-describedby="business"
                                                    onChange={onBusinessThresholdChange}
                                                />
                                            </FloatingLabel>
                                            <InputGroup.Text id="business">%</InputGroup.Text>
                                        </InputGroup>
                                        {errors.business && (
                                            <Form.Text className="text-danger">{errors.business.message}</Form.Text>
                                        )}
                                    </Col>
                                    <Col md>
                                        <InputGroup className="mb-3">
                                            <FloatingLabel controlId="floatingInputGrid" label="Personal">
                                                <Form.Control
                                                    type="number"
                                                    {...register('personal')}
                                                    placeholder="qew"
                                                    disabled
                                                    aria-describedby="personal"
                                                />
                                            </FloatingLabel>
                                            <InputGroup.Text id="personal">%</InputGroup.Text>
                                        </InputGroup>
                                        {errors.personal && (
                                            <Form.Text className="text-danger">{errors.personal.message}</Form.Text>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-flex">
                                        <Button type="submit" displayName="Submit" className="ms-auto px-4">
                                            Edit
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                )}
            </Container>
        </Card>
    );
};
