export function Truncate({ text, size }) {
    // const [show, setShow] =
    if (!text) {
        return null;
    }

    const truncated = text.substring(0, size);
    const isTruncated = truncated.length !== text.length;
    return (
        <div title={text} style={{ cursor: 'pointer' }}>
            {truncated} {isTruncated && <span>{'...'}</span>}
        </div>
    );
}
