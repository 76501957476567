import axios from './axiosSettings';

export const getReceipt = async (receiptId) => {
    try {
        const response = await axios.get('api/receipt/' + receiptId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getReceipts = async (projectId) => {
    try {
        const response = await axios.get('/api/project/' + projectId + '/receipts/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postReceipts = async (projectId, receipt) => {
    try {
        const response = await axios.post('/api/receipt/', {
            ...receipt,
            project: projectId,
        });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postReceiptsCSV = async (file) => {
    try {
        const response = await axios.post('/api/receipt/upload/', file);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const patchReceipt = async (receiptId, newReceipt) => {
    try {
        const response = await axios.patch('/api/receipt/' + receiptId + '/', newReceipt);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteReceipt = async (receiptId) => {
    try {
        const response = await axios.delete('/api/receipt/' + receiptId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
