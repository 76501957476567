import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import styles from "../../../assets/css/Form.module.css";
import { useEffect } from "react";

const FormSchema = z.object({
  make: z
    .string({
      required_error: "The make is required",
      invalid_type_error: "The make must be a string",
    })
    .min(1, { message: "The make is required" }),
  model: z
    .string({ invalid_type_error: "The model must be a string" })
    .min(1, { message: "The model is required" }),
  mpg: z.coerce
    .number({
      required_error: "The mpg is required",
      invalid_type_error: "The mpg must be a number",
    })
    .min(0.001, { message: "MPG is too low" }),
});

export const CarsForm = ({ car, submitRef, parentCallback }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = (data) => {
    parentCallback(data);
  };

  useEffect(() => {
    setValue("mpg", 0)
    if (car) {
      setValue("make", car.make);
      setValue("model", car.model);
      setValue("mpg", car.mpg);
    }
  }, [])

  console.log("Errors:", errors);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={styles["margins"]}>
      <Form.Group className="mb-3" controlId="formMake">
        <Form.Label>Make</Form.Label>
        <Form.Control
          type="text"
          placeholder="Insert the make of the car"
          {...register("make")}
        />
        {errors.make && (
          <Form.Text className="text-danger">{errors.make.message}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formModel">
        <Form.Label>Model</Form.Label>
        <Form.Control
          type="text"
          placeholder="Insert the model of the car"
          {...register("model")}
        />
        {errors.model && (
          <Form.Text className="text-danger">{errors.model.message}</Form.Text>
        )}
      </Form.Group>

      <Form.Group className="mb-3" controlId="formMPG">
        <Form.Label>MPG</Form.Label>
        <Form.Control
          type="number"
          step="0.01"
          placeholder="Insert the MPG of the car"
          {...register("mpg")}
        />
        {errors.mpg && (
          <Form.Text className="text-danger">{errors.mpg.message}</Form.Text>
        )}
      </Form.Group>

      <Button style={{ display: "none" }} type="submit" ref={submitRef} />
    </Form>
  );
};
