import React, { useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { CarsForm } from "../../pages/Settings/Cars/CarsForm";
import { patchCar } from "../../services/CarsService";

export const EditCarModal = ({ car, showState, onClose }) => {
  const submitRef = useRef();

  const handleSubmit = (newCar) => {
    console.log(newCar);
    patchCar(car.id, { project: car.project, ...newCar }).then((response) => {
      console.log(response);
      onClose();
    });
  };

  return (
    <Modal show={showState} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{car.make}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CarsForm
          car={car}
          submitRef={submitRef}
          parentCallback={handleSubmit}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            submitRef.current.click();
          }}
        >
          Edit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
