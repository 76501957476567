import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReceiptForm } from '../../pages/Settings/Receipts/ReceiptForm';
import { patchReceipt } from '../../services/ReceiptsService';
import { useFormStore } from '../../services/stores/forms';

export const EditReceiptModal = ({ refreshReceipts ,actionedReceipt, showState, onClose }) => {
    const submitRef = useRef();
    const [receipt, setReceipt] = useFormStore((state) => [state.receipt, state.setReceipt]);

    useEffect(() => {
        if (receipt && showState) {
            patchReceipt(actionedReceipt?.id, receipt).then((response) => {
                setReceipt(null);
                refreshReceipts();
                onClose();
            });
        }
    }, [receipt]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Receipt #{actionedReceipt?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReceiptForm actionedReceipt={actionedReceipt} ref={submitRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        submitRef.current.click();
                    }}
                >
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
