import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useErrorStore } from '../../services/stores/errors';

export const ErrorModal = ({ showState, onClose }) => {
    const [error, setError] = useErrorStore((state) => [state.error, state.setError]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{error?.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClose} variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
