import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import * as z from 'zod';
import { getReceipts } from '../../services/ReceiptsService';
import { useStore } from '../../services/stores/projects';
import { getLocations } from '../../services/LocationsService';
import Creatable from 'react-select/creatable';
import { DateTime } from 'luxon';

const FormSchema = z.object({
    type: z.string({
        required_error: 'Type is required',
        invalid_type_error: 'The type must be a string',
    }),
    receipt: z.coerce.number({ invalid_type_error: 'Receipt is required' }).optional(),
    start_location: z.coerce.number({ invalid_type_error: 'Receipt is required' }).optional(),
    end_location: z.coerce.number({ invalid_type_error: 'Receipt is required' }).optional(),
    start_date: z.union([z.coerce.date(), z.string().optional()]),
    end_date: z.union([z.coerce.date(), z.string().optional()]),
    generated: z.boolean().optional(),
});

export const FilterForm = ({ filters, submitRef, parentCallback }) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm({ resolver: zodResolver(FormSchema) });
    const projectid = useStore((state) => state.project?.id);

    const [receipts, setReceipts] = useState([]);
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const onSubmit = (data) => {
        console.log(filters);
        parentCallback(data);
    };

    useEffect(() => {
        setIsLoading(true);

        Promise.all([getReceipts(projectid), getLocations()]).then((data) => {
            setReceipts(data[0]);
            setLocations(data[1]);
            if (filters) {
                setValue('start_date', DateTime.fromJSDate(new Date(filters.start_date)).toISODate());
                setValue('end_date', DateTime.fromJSDate(new Date(filters.end_date)).toISODate());
                setValue('start_location', filters.start_location);
                setValue('end_location', filters.end_location);
                setValue('receipt', filters.receipt);
                setValue('generated', filters.generated);
            }
            setIsLoading(false);
        });
    }, [projectid]);

    return (
        <>
            {isLoading ? (
                <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Type</Form.Label>
                        <Form.Select {...register('type')}>
                            <option value="">Choose the type of the trip</option>
                            <option value="personal">Personal</option>
                            <option value="business">Business</option>
                        </Form.Select>
                        {errors.type && <Form.Text className="text-danger">{errors.type.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Receipt</Form.Label>
                        <Select
                            {...register('receipt')}
                            onChange={(option) => setValue('receipt', option.value)}
                            options={receipts.map((receipt) => ({
                                value: receipt.id,
                                label: receipt.name,
                            }))}
                            defaultValue={
                                filters
                                    ? receipts
                                          .map((receipt) => ({ value: receipt.id, label: receipt.name }))
                                          .find((receipt) => receipt.value.toString() === filters.receipt)
                                    : null
                            }
                            isClearable={true}
                        />
                        {errors.receipt && <Form.Text className="text-danger">{errors.receipt.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Start Location</Form.Label>
                        <Select
                            {...register('start_location')}
                            onChange={(option) => setValue('start_location', option.value)}
                            options={locations.map((location) => ({
                                value: location.id,
                                label: location.original_address,
                            }))}
                            defaultValue={
                                filters
                                    ? locations
                                          .map((location) => ({
                                              value: location.id,
                                              label: location.original_address,
                                          }))
                                          .find((location) => location.value.toString() === filters.start_location)
                                    : null
                            }
                            isClearable={true}
                        />
                        {errors.start_location && (
                            <Form.Text className="text-danger">{errors.start_location?.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>End Location</Form.Label>
                        <Select
                            {...register('end_location')}
                            onChange={(option) => setValue('end_location', option.value)}
                            options={locations.map((location) => ({
                                value: location.id,
                                label: location.original_address,
                            }))}
                            defaultValue={
                                filters
                                    ? locations
                                          .map((location) => ({ value: location.id, label: location.original_address }))
                                          .find((location) => location.value.toString() === filters.end_location)
                                    : null
                            }
                            isClearable={true}
                        />
                        {errors.end_location && (
                            <Form.Text className="text-danger">{errors.end_location.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Insert the start date"
                            {...register('start_date')}
                        ></Form.Control>
                        {errors.start_date && (
                            <Form.Text className="text-danger">{errors.start_date.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>End Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Insert the end date"
                            {...register('end_date')}
                        ></Form.Control>
                        {errors.end_date && <Form.Text className="text-danger">{errors.end_date.message}</Form.Text>}
                    </Form.Group>

                    <Form.Check
                        defaultChecked={filters ? filters.generated : false}
                        onChange={(e) => {
                            if (e.target.checked === true) {
                                setValue('generated', true);
                            } else {
                                setValue('generated', false);
                            }
                        }}
                        label="Generated"
                    />

                    <Button variant="primary" ref={submitRef} style={{ display: 'none' }} type="submit" />
                </Form>
            )}
        </>
    );
};
