import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, NavDropdown, Row } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { AiFillCar } from 'react-icons/ai';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CreateProjectModal } from '../components/modals/CreateProjectModal';
import { DeleteProjectModal } from '../components/modals/DeleteProjectModal';
import { EditCarModal } from '../components/modals/EditCarModal';
import { GenerateRecordsModal } from '../components/modals/GenerateRecordsModal';
import { ResetProjectModal } from '../components/modals/ResetProjectModal';
import { getAllProjects, getProjectById } from '../services/ProjectsService';
import { getProjectStatus } from '../services/RecordsService';
import { useStore } from '../services/stores/projects';

export const LayoutNavBar = () => {
    const navigate = useNavigate();
    const [isDeleteModalDisplayed, setIsDeleteModalDisplayed] = useState(false);
    const [isResetModalDisplayed, setIsResetModalDisplayed] = useState(false);
    const [isCreateModalDisplayed, setIsCreateModalDisplyed] = useState(false);
    const [isGenerateModalDisplayed, setIsGenereateModalDisplayed] = useState(false);
    const [isEditCarModalDisplayed, setIsEditCarModalDisplayed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [projects, project, setProjects, setProject, setProjectStatus] = useStore((state) => [
        state.projects,
        state.project,
        state.setProjects,
        state.setProject,
        state.setProjectStatus,
    ]);
    const car = useStore((state) => state.project?.car);

    const getRecordsStatus = useCallback(() => {
        getProjectStatus(project?.id).then((resp) => {
            setProjectStatus(resp?.status);
        });
    }, [project, setProjectStatus]);

    useEffect(() => {
        getAllProjects().then((response) => {
            if (response) {
                setProjects(response);
            }
        });
    }, [project, setProject]);

    useEffect(() => {
        if (projects?.length > 0 && !projects.find((item) => item.id === project?.id)) {
            setProject(null);
        }
    }, [projects, project, setProject]);

    useEffect(() => {
        if (project?.id) {
            const interval = setInterval(getRecordsStatus, 30000);
            return () => clearInterval(interval);
        }
    }, [project?.id, getRecordsStatus]);

    return (
        <Navbar expanded={expanded} bg="primary" variant="dark" expand="lg">
            <Container fluid>
                <Navbar.Brand>Miles-Logging-Ninja</Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(expanded ? false : 'expanded')} />
                <Navbar.Collapse>
                    <Nav onClick={() => setExpanded(false)} className="me-auto">
                        {project && (
                            <>
                                <LinkContainer to="/">
                                    <Nav.Link>Home</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/records">
                                    <Nav.Link>Records</Nav.Link>
                                </LinkContainer>
                                <LinkContainer to="/receipts">
                                    <Nav.Link>Receipts</Nav.Link>
                                </LinkContainer>
                                <NavDropdown title="Settings">
                                    <LinkContainer to="/gas">
                                        <NavDropdown.Item>Gas</NavDropdown.Item>
                                    </LinkContainer>
                                    <LinkContainer to="/threshold">
                                        <NavDropdown.Item>Threshold</NavDropdown.Item>
                                    </LinkContainer>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item
                                        onClick={() => {
                                            setIsDeleteModalDisplayed(true);
                                        }}
                                    >
                                        Delete
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link onClick={() => setIsGenereateModalDisplayed(true)}>Generate</Nav.Link>
                                <GenerateRecordsModal
                                    showState={isGenerateModalDisplayed}
                                    onClose={() => setIsGenereateModalDisplayed(false)}
                                />
                                <DeleteProjectModal
                                    showState={isDeleteModalDisplayed}
                                    onClose={() => setIsDeleteModalDisplayed(false)}
                                />
                                <Nav.Link
                                    onClick={() => {
                                        setIsResetModalDisplayed(true);
                                    }}
                                >
                                    Reset Generation
                                </Nav.Link>
                                <ResetProjectModal
                                    showState={isResetModalDisplayed}
                                    onClose={() => setIsResetModalDisplayed(false)}
                                />
                            </>
                        )}
                    </Nav>
                    <Row>
                        {car && (
                            <Col>
                                <InputGroup>
                                    <Button
                                        style={{
                                            backgroundColor: '#084CB1',
                                            borderColor: '#084CB1',
                                            outlineColor: '#084CB1',
                                        }}
                                        onClick={() => setIsEditCarModalDisplayed(true)}
                                    >
                                        <AiFillCar />
                                    </Button>
                                    <EditCarModal
                                        car={car}
                                        showState={isEditCarModalDisplayed}
                                        onClose={() => setIsEditCarModalDisplayed(false)}
                                    />
                                    <Form.Control value={car.make + ' ' + car.model} />
                                </InputGroup>
                            </Col>
                        )}
                        <Col>
                            <InputGroup>
                                <Button
                                    style={{
                                        backgroundColor: '#084CB1',
                                        borderColor: '#084CB1',
                                        outlineColor: '#084CB1',
                                    }}
                                    onClick={() => setIsCreateModalDisplyed(true)}
                                >
                                    +
                                </Button>
                                <CreateProjectModal
                                    showState={isCreateModalDisplayed}
                                    onClose={() => setIsCreateModalDisplyed(false)}
                                />
                                <Form.Select
                                    onChange={(event) => {
                                        if (event.target.value !== '') {
                                            getProjectById(event.target.value).then((response) => {
                                                setProject(response);
                                            });
                                        } else {
                                            setProject(null);
                                            navigate('');
                                        }
                                    }}
                                    value={project ? project.id : ''}
                                >
                                    <option value="">no project selected</option>
                                    {projects.map((project, index) => (
                                        <option key={index} value={project.id}>
                                            {project.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </InputGroup>
                        </Col>
                    </Row>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
