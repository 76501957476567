import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorStore } from './stores/errors';

const apiUrl = process.env.REACT_APP_API_URL;
console.log('API URL: ', apiUrl);
const instance = axios.create({
    baseURL: apiUrl || '',
});

const AxiosInterceptor = ({ children }) => {
    const [error, setError] = useErrorStore((state) => [state.error, state.setError]);
    const navigate = useNavigate();

    useEffect(() => {
        const resInterceptor = (response) => {
            return response;
        };

        const errInterceptor = (error) => {
            if (!error.response) {
                setError({ message: 'The server is not responding. Please try again later!' });
                navigate('/');
            }

            if (error.response.status === 500) {
                setError(error);
                navigate('/');
            }

            return Promise.reject(error);
        };

        const interceptor = instance.interceptors.response.use(resInterceptor, errInterceptor);

        return () => instance.interceptors.response.eject(interceptor);
    }, [navigate]);

    return children;
};

export default instance;
export { AxiosInterceptor };
