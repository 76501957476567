import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
    recordsPerPage: 50,
};

const storeConfig = (set, get) => ({
    ...initialState,
    setRecordsPerPage: (recordsPerPage) => {
        set(() => ({ recordsPerPage }));
    },
});

export const usePaginationStore = create(persist(storeConfig, { name: 'pagination-storage' }));
