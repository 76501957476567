import axios from './axiosSettings';

export const getAllProjects = async () => {
    try {
        const response = await axios.get('/api/projects/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getProjectById = async (id) => {
    try {
        const response = await axios.get('/api/projects/' + id);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postProject = async (projectName) => {
    try {
        const response = await axios.post('/api/projects/', { name: projectName });
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const patchProject = async (projectId, data) => {
    try {
        const response = await axios.patch('/api/projects/' + projectId + '/', data);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteProject = async (projectId) => {
    try {
        const response = await axios.delete('/api/projects/' + projectId + '/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const resetProject = async (projectId) => {
    try {
        const response = await axios.post('/api/projects/' + projectId + '/reset/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
