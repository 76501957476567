import { zodResolver } from '@hookform/resolvers/zod';
import React, { forwardRef, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';
import Creatable from 'react-select/creatable';
import * as z from 'zod';
import styles from '../../assets/css/Form.module.css';
import { getLocations } from '../../services/LocationsService';
import { useStore } from '../../services/stores/projects';
import { useFormStore } from '../../services/stores/forms';
import { DateTime } from 'luxon';
import { getReceipts } from '../../services/ReceiptsService';
import Select from 'react-select';

const FormSchema = z.object({
    type: z
        .string({
            required_error: 'Type is required',
            invalid_type_error: 'The type must be a string',
        })
        .min(1, { message: 'Type is required' }),
    reason: z
        .string({
            required_error: 'Reason is required',
            invalid_type_error: 'The reason must be a string',
        })
        .min(1, { message: 'Reason is required' }),
    start_location: z.coerce
        .number({
            required_error: 'Start location is required',
            invalid_type_error: 'The start location must be a string',
        })
        .min(1, { message: 'Start location is required' }),
    end_location: z.coerce
        .number({
            required_error: 'End location is required',
            invalid_type_error: 'The end location must be a string',
        })
        .min(1, { message: 'End location is required' }),
    datetime: z.coerce.date({
        required_error: 'Date is required',
        invalid_type_error: 'Date is required',
    }),
    distance: z.coerce
        .number({
            required_error: 'Distance is required',
            invalid_type_error: 'The distance must be a number',
        })
        .min(0.001, { message: 'Distance is required' }),
    tags: z.string().array().optional(),
});

export const RecordForm = forwardRef(({ actionedRecord }, ref) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(FormSchema),
    });

    const projectId = useStore((state) => state.project?.id);
    const [locations, setLocations] = useState([]);
    const [receipts, setReceipts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [record, setRecord] = useFormStore((state) => [state.record, state.setRecord]);
    const [tags, setTags] = useState([
        { value: 'tag1', label: 'tag1' },
        { value: 'tag2', label: 'tag2' },
    ]);

    useEffect(() => {
        setIsLoading(true);

        Promise.all([getReceipts(projectId), getLocations()]).then((data) => {
            setReceipts(data[0]);
            setLocations(data[1]);
            if (actionedRecord) {
                setValue('type', actionedRecord.type);
                setValue('reason', actionedRecord.reason);
                setValue('start_location', actionedRecord.start_location?.id);
                setValue('end_location', actionedRecord.end_location.id);
                setValue('datetime', DateTime.fromISO(actionedRecord.datetime).toISODate());
                setValue('distance', actionedRecord.distance);
            }
            setIsLoading(false);
        });
    }, [projectId]);

    const onSubmit = (data) => {
        setRecord(data);
    };

    return (
        <>
            {isLoading ? (
                <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)} className={styles['margins']}>
                    <Form.Group className="mb-3">
                        <Form.Label>Type</Form.Label>
                        <Form.Select {...register('type')}>
                            <option value="">Choose the type of the trip</option>
                            <option value="personal">Personal</option>
                            <option value="business">Business</option>
                        </Form.Select>
                        {errors.type && <Form.Text className="text-danger">{errors.type.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formReason">
                        <Form.Label>Reason</Form.Label>
                        <Form.Control type="text" placeholder="Insert the reason of the trip" {...register('reason')} />
                        {errors.reason && <Form.Text className="text-danger">{errors.reason.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Start Location</Form.Label>
                        <Creatable
                            {...register('start_location')}
                            onChange={(option) => setValue('start_location', option.value)}
                            isClearable={true}
                            options={locations.map((location) => ({
                                value: location.id,
                                label: location.original_address,
                            }))}
                            defaultValue={
                                actionedRecord
                                    ? locations
                                          .map((location) => ({
                                              value: location.id,
                                              label: location.original_address,
                                          }))
                                          .find((location) => location.value === actionedRecord.start_location?.id)
                                    : null
                            }
                        />
                        {errors.start_location && (
                            <Form.Text className="text-danger">{errors.start_location?.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>End Location</Form.Label>
                        <Creatable
                            {...register('end_location')}
                            onChange={(option) => setValue('end_location', option.value)}
                            isClearable={true}
                            options={locations.map((location) => ({
                                value: location.id,
                                label: location.original_address,
                            }))}
                            defaultValue={
                                actionedRecord
                                    ? locations
                                          .map((location) => ({
                                              value: location.id,
                                              label: location.original_address,
                                          }))
                                          .find((location) => location.value === actionedRecord.end_location.id)
                                    : null
                            }
                        />
                        {errors.end_location && (
                            <Form.Text className="text-danger">{errors.end_location.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDate">
                        <Form.Label>Date</Form.Label>
                        <Form.Control
                            type="date"
                            placeholder="Insert the date when the trip was made"
                            {...register('datetime')}
                        />
                        {errors.datetime && <Form.Text className="text-danger">{errors.datetime.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formDistance">
                        <Form.Label>Distance</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.01"
                            placeholder="Insert the distance of the trip"
                            {...register('distance')}
                        />
                        {errors.distance && <Form.Text className="text-danger">{errors.distance.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <Creatable
                            {...register('tags')}
                            onChange={(options) =>
                                setValue(
                                    'tags',
                                    options.map((option) => option.value)
                                )
                            }
                            defaultValue={
                                actionedRecord ? actionedRecord.tags.map((tag) => ({ value: tag, label: tag })) : null
                            }
                            isMulti
                            options={tags}
                            isClearable={true}
                        />
                        {errors.tags && <Form.Text className="text-danger">{errors.tags.message}</Form.Text>}
                    </Form.Group>

                    <Button style={{ display: 'none' }} ref={ref} type="submit" />
                </Form>
            )}
        </>
    );
});
