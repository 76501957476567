import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { GasForm } from '../../pages/Settings/Gas/GasForm';
import { patchGas } from '../../services/GasService';
import { useFormStore } from '../../services/stores/forms';

export const EditGasModal = ({ actionedGas, refreshGasList, showState, onClose }) => {
    const submitRef = useRef();
    const [gas, setGas] = useFormStore((state) => [state.gas, state.setGas]);

    useEffect(() => {
        if (gas && showState) {
            patchGas(actionedGas.id, gas).then((response) => {
                setGas(null);
                refreshGasList();
                onClose();
            });
        }
    }, [gas]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Record #{actionedGas?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <GasForm actionedGas={actionedGas} ref={submitRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button
                    variant="primary"
                    onClick={() => {
                        submitRef.current.click();
                    }}
                >
                    Edit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
