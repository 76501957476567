import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { AiFillCheckSquare } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { getProjectById, resetProject } from '../../services/ProjectsService';
import { useStore } from '../../services/stores/projects';

export const ResetProjectModal = ({ showState, onClose }) => {
    const navigate = useNavigate();
    const projectId = useStore((state) => state.project?.id);
    const [projectName, setProjectName] = useState('');
    const [loading, setLoading] = useState(false);
    const [resetInfo, setResetInfo] = useState(null);

    useEffect(() => {
        getProjectById(projectId).then((response) => {
            setProjectName(response?.name);
        });
    }, [projectId]);

    const handleReset = () => {
        setLoading(true);
        resetProject(projectId).then((response) => {
            navigate('/');
            setTimeout(() => {
                setLoading(false);
                setResetInfo(response);
            }, 1000);
        });
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {resetInfo ? (
                        <>Reset Done Successfully</>
                    ) : (
                        <>Are you sure you want to reset generation for {projectName}?</>
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Spinner animation="grow" variant="success" size="lg" className="d-flex my-5 mx-auto" />
                ) : (
                    <>
                        {resetInfo ? (
                            <>
                                <h6>
                                    <AiFillCheckSquare color="green" /> Deleted {resetInfo.deleted_records} generated
                                    records.
                                </h6>
                                <h6>
                                    <AiFillCheckSquare color="green" /> Removed {resetInfo.removed_receipts} receipts.
                                </h6>
                            </>
                        ) : (
                            <>
                                <p>This action is irevertible!</p>
                                <p>It will delete all generated records of this project.</p>
                                <p>It will remove all receipts from records of that project.</p>
                            </>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        onClose();
                        setResetInfo(null);
                    }}
                >
                    Cancel
                </Button>
                {!resetInfo && (
                    <Button variant="danger" onClick={handleReset}>
                        Reset generation
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};
