import axios from './axiosSettings';

export const getLocations = async () => {
    try {
        const response = await axios.get('/api/location');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getLocationById = async (locationId) => {
    try {
        const response = await axios.get('/api/location/' + locationId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
