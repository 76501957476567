import { create } from 'zustand';

const initialState = {
    error: null,
};

const storeConfig = (set, get) => ({
    ...initialState,

    setError: (error) => {
        set(() => ({error: error}));
    },
});

export const useErrorStore = create(storeConfig);
