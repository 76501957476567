import axios from './axiosSettings';

export const getGas = async () => {
    try {
        const response = await axios.get('/api/gas/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const getGasById = async (gasId) => {
    try {
        const response = await axios.get('/api/gas/' + gasId);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const postGasCSVFile = async (file) => {
    try {
        const response = await axios.post('/api/gas/upload/', file);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const deleteGas = async (gasId) => {
    try {
        const response = await axios.delete('/api/gas/' + gasId + '/');
        return response.data;
    } catch (error) {
        console.log(error);
    }
};

export const patchGas = async (gasId, gas) => {
    try {
        const response = await axios.patch('/api/gas/' + gasId + '/', gas);
        return response.data;
    } catch (error) {
        console.log(error);
    }
};
