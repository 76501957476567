import React, { useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { deleteReceipt, patchReceipt } from '../../services/ReceiptsService';

export const DeleteReceiptModal = ({ actionedReceipt, showState, onClose, refreshReceipts }) => {
    const onDelete = () => {
        deleteReceipt(actionedReceipt.id).then((response) => {
            console.log(response);
            refreshReceipts();
            onClose();
        });
    };

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Receipt #{actionedReceipt?.id}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This action is irevertible!</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={onDelete}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
