import { zodResolver } from '@hookform/resolvers/zod';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import * as z from 'zod';
import { getReceipts } from '../../services/ReceiptsService';
import { useStore } from '../../services/stores/projects';

const FormSchema = z.object({
    receipt: z.coerce.number({ required_error: 'Receipt is required' }),
    start_date: z.coerce.date({
        required_error: 'Starting Date is required',
        invalid_type_error: 'The start date must be a date',
    }),
    end_date: z.union([z.coerce.date(), z.string().optional()]),
    tags: z.string().array().optional(),
});

const options = [
    { value: 'tag1', label: 'tag1' },
    { value: 'tag2', label: 'tag2' },
];

export const GenerateRecordsForm = ({ onClose, submitRef, handleFormSubmit }) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(FormSchema),
    });
    const [receipts, setReceipts] = useState([]);
    const projectId = useStore((state) => state.project?.id);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getReceipts(projectId).then((response) => {
            if (response) {
                setReceipts(response);
                setLoading(false);
            } else {
                onClose();
            }
        });
    }, []);

    const onSubmit = (data) => {
        handleFormSubmit({
            ...data,
            start_date: DateTime.fromJSDate(data.start_date).toISODate(),
            end_date: data.end_date ? DateTime.fromJSDate(data.end_date).toISODate() : '',
        });
    };

    return (
        <>
            {loading ? (
                <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Receipt</Form.Label>
                        <Select
                            {...register('receipt')}
                            onChange={(option) => {
                                setValue('receipt', option.value);
                                setValue(
                                    'start_date',
                                    DateTime.fromISO(
                                        receipts.find((receipt) => receipt.id === option.value).date
                                    ).toISODate()
                                );
                            }}
                            options={receipts.map((receipt) => ({
                                value: receipt.id,
                                label: receipt.name,
                            }))}
                            isClearable={true}
                        />
                        {errors.receipt && <Form.Text className="text-danger">{errors.receipt.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Start date</Form.Label>
                        <Form.Control type="date" {...register('start_date')}></Form.Control>
                        {errors.start_date && (
                            <Form.Text className="text-danger">{errors.start_date.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>End date</Form.Label>
                        <Form.Control type="date" {...register('end_date')}></Form.Control>
                        {errors.end_date && <Form.Text className="text-danger">{errors.end_date.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Tags</Form.Label>
                        <CreatableSelect
                            isMulti
                            {...register('tags')}
                            onChange={(options) =>
                                setValue(
                                    'tags',
                                    options.map((option) => option.value)
                                )
                            }
                            options={options}
                            isClearable={true}
                        />
                        {errors.tags && <Form.Text className="text-danger">{errors.tags.message}</Form.Text>}
                    </Form.Group>

                    <Button style={{ display: 'none' }} ref={submitRef} type="submit" />
                </Form>
            )}
        </>
    );
};
