import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReceiptForm } from '../../pages/Settings/Receipts/ReceiptForm';
import { postReceipts } from '../../services/ReceiptsService';
import { useFormStore } from '../../services/stores/forms';
import { useStore } from '../../services/stores/projects';

export const CreateReceiptModal = ({ showState, onClose, refreshReceipts }) => {
    const submitRef = useRef();
    const projectId = useStore((state) => state.project?.id);
    const [receipt, setReceipt] = useFormStore((state) => [state.receipt, state.setReceipt]);

    useEffect(() => {
        if (receipt && showState) {
            postReceipts(projectId, receipt).then(refreshReceipts);
            setReceipt(null);
            onClose();
        }
    }, [receipt]);

    return (
        <Modal show={showState} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>Create a new receipt</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ReceiptForm ref={submitRef} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => submitRef.current.click()}>
                    Add
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
