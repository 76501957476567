import { create } from 'zustand';

const initialState = {
    gas: null,
    receipt: null,
    record: null,
};

const storeConfig = (set, get) => ({
    ...initialState,

    setGas: (gas) => {
        set(() => ({ gas: gas }));
    },

    setReceipt: (receipt) => {
        set(() => ({ receipt: receipt }));
    },

    setRecord: (record) => {
        set(() => ({ record: record }));
    },
});

export const useFormStore = create(storeConfig);