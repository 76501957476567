import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { FilterForm } from "../../pages/Records/FilterForm";

export const FilterModal = ({
  filterSubmit,
  showState,
  onClose,
  filters,
}) => {
  const filterRef = useRef();

  const handleSubmit = (data) => {
    filterSubmit(data);
    onClose();
  };

  return (
    <Modal show={showState} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Filter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterForm
          filters={filters}
          parentCallback={handleSubmit}
          submitRef={filterRef}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            filterRef.current.click();
          }}
        >
          Filter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
