import { zodResolver } from '@hookform/resolvers/zod';
import React, { forwardRef, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useStore } from '../../../services/stores/projects';
import { useFormStore } from '../../../services/stores/forms';
import { DateTime } from 'luxon';
import * as z from 'zod';

const FormSchema = z.object({
    price: z.coerce.number({ required_error: 'Price is required' }).min(0, { message: 'Price is too low' }),
    start_date: z.coerce.date({
        required_error: 'Start Date is required',
        invalid_type_error: 'Start Date is required',
    }),
    end_date: z.coerce.date({
        required_error: 'End date is required',
        invalid_type_error: 'End Date is required',
    }),
});

export const GasForm = forwardRef(({ actionedGas }, ref) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(FormSchema),
    });
    const projectId = useStore((state) => state.project?.id);
    const [gas, setGas] = useFormStore((state) => [state.gas, state.setGas]);

    useEffect(() => {
        setValue('price', actionedGas.price);
        setValue('start_date', DateTime.fromISO(actionedGas.start_date).toISODate());
        setValue('end_date', DateTime.fromISO(actionedGas.end_date).toISODate());
    }, [projectId]);

    const onSubmit = (data) => {
        setGas(data);
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
                <Form.Label>Price</Form.Label>
                <Form.Control
                    type="number"
                    step="0.01"
                    placeholder="Insert the price"
                    {...register('price')}
                ></Form.Control>
                {errors.price && <Form.Text className="text-danger">{errors.price.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Start Date</Form.Label>
                <Form.Control type="date" {...register('start_date')}></Form.Control>
                {errors.start_date && <Form.Text className="text-danger">{errors.start_date.message}</Form.Text>}
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>End Date</Form.Label>
                <Form.Control type="date" {...register('end_date')}></Form.Control>
            </Form.Group>
            <Button ref={ref} style={{ display: 'none' }} type="submit" />
        </Form>
    );
});
