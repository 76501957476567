import { zodResolver } from '@hookform/resolvers/zod';
import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { useStore } from '../../../services/stores/projects';
import { useFormStore } from '../../../services/stores/forms';
import { DateTime } from 'luxon';

const FormSchema = z.object({
    price: z.coerce
        .number({
            required_error: 'Price is required',
            invalid_type_error: 'Price must be a number',
        })
        .min(0.001, { message: 'Price is too low' }),
    date: z.coerce.date({ required_error: 'Date is required' }),
    type_of_station: z.string({ required_error: 'Type of station is required' }).optional(),
    payment_type: z
        .string({ required_error: 'Payment Type is required' })
        .min(1, { message: 'Payment Type is requried' }),
    address: z.string().optional(),
});

export const ReceiptForm = forwardRef(({ actionedReceipt }, ref) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm({
        resolver: zodResolver(FormSchema),
    });
    const [isLoading, setIsLoading] = useState(true);
    const projectId = useStore((state) => state.project?.id);
    const [receipt, setReceipt] = useFormStore((state) => [state.receipt, state.setReceipt]);

    useEffect(() => {
        setIsLoading(true);
        if (actionedReceipt) {
            setValue('date', DateTime.fromISO(actionedReceipt.date).toISODate());
            setValue('price', actionedReceipt.price);
            setValue('type_of_station', actionedReceipt.type_of_station);
            setValue('payment_type', actionedReceipt.payment_type.toLowerCase());
            setValue('address', actionedReceipt.address);
        }
        setIsLoading(false);
    }, [projectId]);

    const onSubmit = (data) => {
        setReceipt(data);
    };

    return (
        <>
            {isLoading ? (
                <Spinner animation="border" role="status" className="d-flex mx-auto my-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            ) : (
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group className="mb-3">
                        <Form.Label>Price</Form.Label>
                        <Form.Control
                            type="number"
                            step="0.01"
                            placeholder="Insert the price"
                            {...register('price')}
                        ></Form.Control>
                        {errors.price && <Form.Text className="text-danger">{errors.price.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" placeholder="Insert the date" {...register('date')}></Form.Control>
                        {errors.date && <Form.Text className="text-danger">{errors.date.message}</Form.Text>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Type of station</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Insert the type of station."
                            {...register('type_of_station')}
                        ></Form.Control>
                        {errors.type_of_station && (
                            <Form.Text className="text-danger">{errors.type_of_station.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Payment Type</Form.Label>
                        <Form.Select {...register('payment_type')}>
                            <option value="">Choose the payment type</option>
                            <option value="cash">Cash</option>
                            <option value="credit">Credit</option>
                            <option value="prepaid">Prepaid</option>
                        </Form.Select>
                        {errors.payment_type && (
                            <Form.Text className="text-danger">{errors.payment_type.message}</Form.Text>
                        )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Insert the address" {...register('address')} />
                        {errors.address && <Form.Text className="text-danger">{errors.address.message}</Form.Text>}
                    </Form.Group>

                    <Button ref={ref} style={{ display: 'none' }} type="submit" />
                </Form>
            )}
        </>
    );
});
