import React from 'react';
import { Pagination, Form, Container, Col, Row } from 'react-bootstrap';

export const CustomPagination = ({ dataPerPage, totalData, paginate, currentPage, setDataPerPage }) => {
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(totalData / dataPerPage); i++) {
        pageNumbers.push(i);
    }

    let start = 1,
        end = pageNumbers.length;
    if (currentPage - 2 > 1) {
        start = currentPage - 2;
    }
    if (currentPage + 2 < pageNumbers.length) {
        end = currentPage + 2;
    }

    return (
        <Container className="m-1">
            <Row>
                <Col className="offset-1 me-4">
                    <Pagination>
                        <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} />
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {start !== 1 && <Pagination.Ellipsis />}
                        {pageNumbers.slice(start - 1, end).map((number) => (
                            <Pagination.Item
                                key={number}
                                onClick={() => paginate(number)}
                                active={currentPage === number}
                            >
                                {number}
                            </Pagination.Item>
                        ))}
                        {end !== pageNumbers.length && <Pagination.Ellipsis />}
                        <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === pageNumbers.length}
                        />
                        <Pagination.Last
                            onClick={() => paginate(pageNumbers.length)}
                            disabled={currentPage === pageNumbers.length}
                        />
                    </Pagination>
                </Col>
                <Col className='offset-lg-5 offset-md-2'>
                    <Form.Select
                        value={dataPerPage}
                        onChange={(e) => {
                            setDataPerPage(e.target.value);
                        }}
                        className="ms-3"
                    >
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </Form.Select>
                </Col>
            </Row>
        </Container>
    );
};
