import React from "react";
import { useDropzone } from "react-dropzone";
import dropzoneStyles from "../assets/css/Dropzone.module.css";

export const FilesDropzone = ({ onDrop, accept }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  return (
    <div className={dropzoneStyles["dropzone-div"]} {...getRootProps()}>
      <input className="dropzone-input" {...getInputProps()} />
      <div className="text-center m">
        {isDragActive ? (
          <p className="dropzone-content">Release to drop the files here</p>
        ) : (
          <p className="dropzone-content">
            Drag and drop some files here, or click to select files
          </p>
        )}
      </div>
    </div>
  );
};
