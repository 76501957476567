import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import * as z from "zod";
import styles from "../../assets/css/Form.module.css";

const FormSchema = z.object({
  name: z
    .string({
      required_error: "Name is required",
      invalid_type_error: "The name must be a string",
    })
    .min(1, { message: "Name is required" }),
});

export const ProjectForm = ({ parentCallback, name, submitRef }) => {
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: name,
    },
  });

  const onSubmit = (newProject) => {
    parentCallback(newProject.name)
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} className={styles["margins"]}>
        <Form.Group className="mb-2" controlId="formName">
          <Form.Control
            type="text"
            placeholder="Insert the project name"
            {...register("name")}
          />
          {errors.name && (
            <Form.Text className="text-danger">{errors.name.message}</Form.Text>
          )}
        </Form.Group>

        <Button ref={submitRef} style={{ display: "none" }} type="submit" />
      </Form>
    </div>
  );
};
